
<template>
    <div ref="tosspayments" class="tosspayments" style="display: none">
        <!-- 결제 UI -->
        <div id="payment-method"></div>
        <!-- 이용약관 UI -->
        <div id="agreement"></div>
        <!-- 결제하기 버튼 -->
         <div class="btn-wrapper">
             <button class="btn" id="payment-button" @click="request()">결제하기</button>
             <button class="btn cancel" id="payment-button" @click="cancel()">취소하기</button>
         </div>
    </div>
</template>
<script>
import api from "@/api";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
export default {
    data(){
        return {
            show: false,
            tossPayments: null,
            widgets: null,

            orderNo: this.$route.query.orderNo,
            order: null,
        };
    },
    methods: {
        async init() {
            let { order, clientKey } = await api.plugins.toss.tosspayments.request.get({ params: { orderNo: this.orderNo } });
            this.order = order;

            var tossPayments = this.tossPayments = await loadTossPayments(clientKey);
            var widgets = this.widgets = tossPayments.widgets({
                customerKey: "q0dc9ERyZFRYNGkcfS3YQ",
            });

            // ------ 주문의 결제 금액 설정 ------
            await widgets.setAmount({
                currency: "KRW",
                value: order.paymentAmount,
            });

            // ------  결제 UI 렌더링 ------
            await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: order.paymentMethod,
            }),

            // ------  이용약관 UI 렌더링 ------
            await widgets.renderAgreement({ selector: "#agreement", variantKey: "AGREEMENT" });

            this.$refs.tosspayments.style.display = "";
           
        },
        async request(){
            var widgets = this.widgets;
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            await widgets.requestPayment({
                orderId: this.orderNo,
                orderName: this.order.orderName,
                successUrl: window.location.origin + "/api/plugins/toss/tosspayments/confirm",
                failUrl: window.location.origin + `/api/plugins/toss/tosspayments/fail`,
                customerEmail: this.order.buyer.email,
                customerName: this.order.buyer.name,
                customerMobilePhone: this.order.buyer.phone,
            });
        },
        cancel(){
            window.parent?.tossCancel?.();
            window.opener?.tossCancel?.();
        }
    },
    mounted(){
        window.tossComplete = (payload) => {
            window?.parent?.tossComplete(payload);
            window?.opener?.tossComplete(payload);
        };
        this.init();
    },
}
</script>
<style scoped>
.tosspayments {
    max-width: 540px;
    margin: auto;
    background: #ffffff;
    padding-bottom: 24px;
}
.tosspayments .btn-wrapper {
    padding: 0 24px;
}
.tosspayments .btn {
    width: 100%;
    padding: 11px 22px;
    border: none;
    border-radius:  8px;

    background-color: #3282f6;
    color: #f9fcff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
.tosspayments .btn {
    width: 100%;
    padding: 11px 22px;
    border: none;
    border-radius:  8px;

    background-color: #3282f6;
    color: #f9fcff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
.tosspayments .btn.cancel {
    width: 100%;
    padding: 11px 22px;
    border: 1px solid black;
    border-radius:  8px;
    margin-top: 8px;

    background-color: #ffffff;
    color: black;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
</style>